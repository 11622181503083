import axios, {AxiosResponse} from "axios";
import {API_BASE, API_CONFIG, errorMessageBase} from "../api/apiConfig";

const API = API_BASE + "/authentication"

export interface UserLoginInfo {
    email: string;
    password: string;
}

export interface UserRegisterInfo extends UserLoginInfo {
    username: string;
    password2: string;
}

export const authRegisterErrors: string[] = errorMessageBase.concat([
    "Sorry, this E-mail is not valid. A valid E-Mail has a @ sign.",
    "Sorry, this E-Mail is already used. If you forgot your password you can <a href='/forgot'>Reset your Password</a>.",
    "Sorry, your password needs at least 6 characters.",
    "Sorry, this Username is already taken.",
    "Sorry, your Username triggered our profanity filter. Please use a different username. If you believe your username to be ok, contact us and we can change your username later.",
]);

export function authRegister(info: UserLoginInfo): Promise<AxiosResponse<string>> {
    return axios.post(API+"/register.php", info, API_CONFIG());
}

export const authLoginErrors: string[] = errorMessageBase.concat([
    "Sorry, we could not find a user with this E-mail/password. Please check your input and try again."
]);

export function authLogin(info: UserLoginInfo): Promise<AxiosResponse<string>> {
    return axios.post(API+"/login.php", info, API_CONFIG());
}

export const authEmailVerifyErrors: string[] = errorMessageBase.concat([
    "Sorry, the Security Code seems to be wrong. Please check and try again. If you requested a new Security code only the newest is valid."
]);

export function authEmailVerify(link: string, code: string): Promise<AxiosResponse<void>> {
    return axios.get(API+"/email-confirm.php?link=" + link + "&code=" + code, API_CONFIG());
}

export function authForgotRequest(email: string): Promise<AxiosResponse<void>> {
    return axios.post(API+"/forgot.php?action=email", {email}, API_CONFIG());
}

export function authForgotCode(uid: number, link: string, code: string): Promise<AxiosResponse<string>> {
    return axios.post(API+"/forgot.php?action=code", {uid, link, code}, API_CONFIG());
}

//export const eventList = useApiData<EventDateListDto[], string>(EventEndpoint.GET.eventDates_list);
//export const userApiLogin = API_BASE + ""; //replaces EventEndpoint.GET.eventDates_list

/*export function emailBasicSend(email: EmailBasic): Promise<AxiosResponse<void>> {
    return axios.post(API_BASE+"/emails/"+email.toGroup+(email.id?"/"+email.id:""), email, {headers: {Authorization: "Bearer "+getToken()}})
}*/