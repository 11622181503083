import {Link} from "react-router-dom";
import {ButtonFancy} from "./ButtonFancy";

export default function LandingPage(props: { surpressRedirct?: boolean }) {
    return <section id="hero" className="hero d-flex align-items-center">
        <div className="m-auto mw-100 position-relative">
            {/* wings */}
            <div className="text-center position-absolute start-0" style={{top: '48%'}}>
                <img id="wingLeft" src="/assets/img/Wings-butterfly-orange.png" alt="" style={{
                    marginLeft:'40%',marginTop:'-100%',filter: 'drop-shadow(2px 4px 10px black)',
                    maxWidth: "30vw"
                }}/>
            </div>
            <div className="text-center position-absolute start-100" style={{top: '48%'}}>
                <img id="wingRight" src="/assets/img/Wings-butterfly.png" alt="" style={{
                    marginLeft:'-80%',marginTop:'-100%',filter: 'drop-shadow(2px 4px 10px black)',
                    maxWidth: "30vw"
                }}/>
            </div>

            {/* bg */}
            <img src="/assets/icons/icon.png" alt="Fairy Tale Fables" className="mw-100" style={{filter: 'drop-shadow(2px 4px 10px black)'}}/>

            {/* register button*/}
            <div className="text-center position-absolute start-50" style={{top: '80%'}}>
                <Link className="nav-link fs-5" to="/app/register" style={{marginLeft:'-100%'}}><ButtonFancy bg="blue">Register</ButtonFancy></Link>
            </div>
        </div>
    </section>;
}