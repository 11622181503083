import React, {FormEvent, ReactElement, useCallback, useEffect, useState} from "react";
import {MessageDisplay, MessageState} from "../components/Message";

import {ButtonFancy} from "../basic/ButtonFancy";
import {payment} from "./PaymentService";
import {AxiosError, AxiosResponse} from "axios";
import {ErrorObject, getError} from "../api/apiConfig";
import {authLoginErrors} from "../authentication/AuthenticationService";
import {ClimateBanner} from "../components/ClimateBanner";

export const Subscription = (props: {id?: string, subscriptionActive: boolean}): ReactElement => {
    const [message, messageSet] = useState<MessageState>();
    const [submitting, submittingSet] = useState(false);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        const paymentQuery = query.get("payment");
        if(paymentQuery && paymentQuery==="subscription") {
            if (query.get("success")) {
                messageSet({type: 'success', message: "Payment confirmed! You will shortly receive an email confirmation."});
            }

            if (query.get("canceled")) {
                messageSet({type: 'warning', message: "Order cancelled."});
            }

            if(props.id) {
                const scrollTarget = document.getElementById(props.id);
                console.log(scrollTarget);
                if(scrollTarget) scrollTarget.scrollIntoView();
            }
        }
    }, [props.id]);

    const handleCheckout = useCallback((e: FormEvent): void => {
        e.preventDefault();
        if(submitting) return;
        submittingSet(true);
        payment("subscription").then((response: AxiosResponse<string>) => {
            // go to URL
            window.location.href = response.data;
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, authLoginErrors)});
            submittingSet(false);
        }); // sic!: NO submittingSet(false) - go to different href
    }, [submitting]);

    return <div className="position-relative">
        <div id={props.id} className="scroll-target" />
        <form onSubmit={handleCheckout}>
            <div className="border rounded-2 border-2 border-dark p-1">
                <MessageDisplay message={message} />

                <div className="d-flex flex-column flex-md-row justify-content-center">
                    <div className="text-center px-3 d-flex flex-column justify-content-center">
                        <img width="180" className="d-inline-block filter-saturate"
                             src="/assets/img/pay-unlimited.png"
                             alt=""
                        />
                    </div>
                    <div className="description p-3 d-flex flex-column justify-content-center">
                        <h3 className="mb-0">Fairytales Unlimited</h3>
                        <h5 className="text-muted mb-0">Battle Pass</h5>
                        <h5 className="">4.99&euro; for 30 days</h5>
                        <h6 className="mb-3">For 30 days, get +25% more Crystals from playing games, play unlimited Chaos Games and create unlimited Custom Games with Custom Rules without paying in-game currency.</h6>

                        {props.subscriptionActive ? <div className="text-success">
                            Subscription active
                        </div> : <ButtonFancy className="w-100 mb-2" bg="purple" type="submit" disabled={submitting}>
                            Checkout
                        </ButtonFancy>}
                        <ClimateBanner />
                    </div>
                </div>
                    <div className="text-center">
                    </div>
            </div>
        </form>
    </div>;
}