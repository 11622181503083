import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";
import AppOutletBreadcrumb from "./AppOutletBreadcrumb";
import {Footer} from "../basic/Footer";
import {setToken} from "../api/apiConfig";



export default function AppOutlet() {

    const isAuthenticated = useAuth().authenticated
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        // set token
        const query = new URLSearchParams(window.location.search);
        const loginQuery = query.get("token");
        if(loginQuery) {
            setToken(loginQuery);
            query.delete("token");
            const newUrl = `${window.location.pathname}?${query.toString()}`;
            window.history.replaceState({}, '', newUrl);
            // Reload the page
            window.location.reload();
            return;
        }
        else {
            if (!isAuthenticated) {
                console.log("Redirect to login: " + window.location.href, query.get("token"));
                const redirectURL = encodeURIComponent(location.pathname + location.search)
                navigate("/app/login?redirect=" + redirectURL)
            }
        }
    }, [isAuthenticated, location.pathname, location.search, navigate])

    const navLinks = [
        {to: "/app", title: "Profile", end: true},
    ]

    return <>
        <Header links={navLinks} />
        <div className='d-md-none header-space' />
        <AppOutletBreadcrumb />
        <main className="mt-4 p-2">
            <section className="inner-page">
                <Outlet />
            </section>
        </main>
        <Footer />
        <button className="back-to-top d-flex align-items-center justify-content-center btn btn-link"><i
            className="bi bi-arrow-up-short"></i></button>
    </>
}