import React, {ReactElement, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Card} from "react-bootstrap";
import {gameProfileDto, gameUserInfoErrors, ProfileDto} from "./GameService";
import AsyncLoadingAnimation from "../components/AsyncLoadingAnimation";
import {ErrorObject, getError} from "../api/apiConfig";
import {AxiosError, AxiosResponse} from "axios";
import {MessageDisplay, MessageState} from "../components/Message";
import {Subscription} from "../payments/Subscription";
import {BooksBuyDisplay} from "../payments/BooksBuyDisplay";

export const Profile = (): ReactElement => {
    // const [submitting, submittingSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [dto, dtoSet] = useState<ProfileDto>();
    const navigate = useNavigate();

    useEffect(() => {
        // get user info request
        gameProfileDto().then((response: AxiosResponse<ProfileDto>) => {
            console.log(response.data);

            dtoSet(response.data);
        }, (error: AxiosError<ErrorObject>) => {
            messageSet({type: "danger", message: getError(error.response?.data, gameUserInfoErrors)});
        });
    }, [navigate])

    const subscriptionActive = dto!==undefined && new Date(dto.userInfo.subscriptionUntil)>new Date();

    return <div className="container d-flex align-items-center justify-content-center">
        <Card style={{maxWidth: "800px"}} className="w-100 bg-fancy text-white">
            <div className="bg-fancy-offset"/>
            <h2 className="text-center">Profile</h2>
            <MessageDisplay message={message}/>

            {dto!==undefined ? <div className="container">
                {/*name*/}
                <div className="text-center">
                    <div className="d-inline-block playerName">
                        {dto.userInfo.name}
                    </div>
                </div>

                {/*resources*/}
                <h4 className="mb-0">Resources</h4>
                <div className="row mb-2">
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/shield_18.png" width="60" style={{marginTop: '-3px'}} />
                            <div className="d-inline-block">Ranking Points: {dto.userInfo.mmr}</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/MiningIcons_86_t.png" width="53" />
                            <div className="d-inline-block">Crystals: {dto.userInfo.crystals}</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/Quest_35.png" width="68" style={{marginTop: '-6px'}} />
                            <div className="d-inline-block">Fairytales: {dto.userInfo.books}</div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 p-2">
                        <div className="bg-simple" style={{height: '70px'}}>
                            <img className="me-2 resource-icon" alt="" src="/assets/img/Icon_Crown.png" width="65" style={{marginTop: '-6px'}} />
                            <div className="d-inline-block">Crowns: {dto.userInfo.crowns}</div>
                        </div>
                    </div>
                </div>

                {/*Subscription*/}
                <div className="mb-3">
                    <h4 className="mb-0">Subscription</h4>
                    <div className="mb-2">
                        {subscriptionActive ? <div>
                            <div>Subscription <span className="text-success">active</span> until: {'' + dto.userInfo.subscriptionUntil}</div>
                            <div><Link to="https://billing.stripe.com/p/login/bIY6rC8rE3gz7kcdQQ" target="_blank">Manage your Subscription and view Payment History</Link></div>
                        </div> : <>
                            Subscription <span className="text-warning">not</span> active. Last Subscription was active until: {'' + dto.userInfo.subscriptionUntil}
                        </>}
                    </div>

                    <Subscription id="subscription" subscriptionActive={subscriptionActive} />
                </div>

                {/*Books*/}
                <div className="">
                    <h4>Buy Fairytales</h4>

                    <BooksBuyDisplay id="books-small" payment="books-small" dto={dto} />
                    <BooksBuyDisplay id="books-large" payment="books-large" dto={dto} />
                    <BooksBuyDisplay id="books-huge" payment="books-huge" dto={dto} />
                    <BooksBuyDisplay id="books-endless" payment="books-endless" dto={dto} />
                </div>
            </div> : <div>
                <AsyncLoadingAnimation height="400px" width="100%"/>
            </div>}
        </Card>
    </div>;
};