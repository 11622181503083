import {useCallback, useState} from "react";
import {Container, Navbar} from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import {Link} from "react-router-dom";
import {isLoggedIn} from "../api/apiConfig";
import useLogout from "../hooks/useLogout";
import useScrollEventListener from "../hooks/useScrollEvenListener";
import {ButtonFancy} from "../basic/ButtonFancy";


export default function Header(props: {
    links: { to: string, title: string, end?: boolean }[],
    webpage?: boolean
}): JSX.Element {

    const collapsHeader = useScrollEventListener({scrollY: 10}).triggering
    const [toggle, setToggle] = useState(false)
    const logout = useLogout()


    const navLinkClassName = useCallback((props: { isActive: boolean, isPending: boolean }) => {
        const baseClass = ["nav-link", "scrollto", "px-3"]
        props.isActive && baseClass.push("active")
        return baseClass.join(" ")
    }, [])

    return <header id="header" className={'header fixed-top header-scrolled'}>
        <img className="d-lg-none position-absolute top-0 start-0" style={{minWidth: '100%', height: '65px'}} src='/assets/img/bar-top.png' alt=''/>
        <img className="d-none d-lg-block position-absolute top-0 start-0 h-100" style={{minWidth: '100%'}} src='/assets/img/bar-top.png' alt=''/>
        <Container fluid className="container-xl">
            <Navbar collapseOnSelect expand="lg" className="w-100 d-flex align-items-center justify-content-between">
                <Link to="/" className="logo d-flex align-items-center fw-bold fs-4"><img
                    src="/assets/img/title-text-singleline.png" alt="Fairy Tale Fables" className="h-100"/></Link>
                <NavbarToggle className="bg-white" onClick={() => setToggle(!toggle)}/>
                <NavbarCollapse style={{flexGrow: "unset"}}>
                    {
                        props.links.map(link => <Link to={link.to} className={navLinkClassName+" "}
                                                         key={link.to}>{link.title}</Link>)
                    }
                    {
                        isLoggedIn() ?
                            <>
                                <Link className="nav-link px-3" to={props.webpage ? "/app" : "/"}>{props.webpage ? "To Profile" : "To Website"}</Link>
                                <ButtonFancy bg="red" onClick={logout} className="ms-4">Logout</ButtonFancy></>
                            : <>
                                <Link className="nav-link" to="/app/login">Login</Link>
                                <Link to="/app/register"><ButtonFancy bg="blue">Register</ButtonFancy></Link>
                            </>
                    }
                </NavbarCollapse>
                {/* <i className="bi bi-list mobile-nav-toggle"></i> */}
            </Navbar>
        </Container>
    </header>
}