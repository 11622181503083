import React, {ReactElement} from "react";
import {Link} from "react-router-dom";

export const Footer = (): ReactElement => {
    return <footer id="footer" className="footer">
        <div className="footer-top bg-white">
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-5 col-md-12 footer-info">
                        <Link to="/" className="logo d-flex align-items-center">
                            {/*<img src="/assets/icons/android/android-icon-192x192.png" alt=""/>*/}
                            <span>Fairytale Fables</span>
                        </Link>
                        <h5>
                            Create your own tales.
                        </h5>
                        {/*<p></p>*/}
                    </div>
                    {/*<div className="col-lg-2 col-6 footer-links">
                        <h4>Useful Links</h4>
                    </div>
                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Our Services</h4>
                    </div>*/}
                    <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                        <h4>Contact Us</h4>
                        <p>
                            Leonardo Wang<br/>
                            Obere Augartenstraße 12-14/1/12<br/>
                            1020 Wien<br/>
                            Europe - Austria<br/>
                            E-Mail: <a className="text-black" href="mailto:support@fairytalefables.com">support@fairytalefables.com</a><br/>
                        </p>
                        <Link to="/app/delete" className="logo d-flex align-items-center text-danger">
                            <span>Delete your Account here</span>
                        </Link>
                        <Link to="/assets/privacy-policy.html" rel="noopener noreferrer" target="_blank" className="logo d-flex align-items-center text-black">
                            <span>Privacy Policy</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                {new Date().getFullYear()} &copy; Copyright <strong>Fairytale Fables</strong>.<br/>
                All Rights Reserved
            </div>
        </div>
    </footer>;
};