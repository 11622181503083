import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouteObject, RouterProvider} from "react-router-dom";
import RegisterPage from "./authentication/RegisterPage";
import LoginPage from "./authentication/LoginPage";
import NotFoundPage from "./basic/NotFoundPage";
import LandingPage from "./basic/LandingPage";
import {Crumb} from './components/Breadcrumb';
import WebpageOutlet from './Outlets/WebpageOutlet';
import AppOutlet from './Outlets/AppOutlet';
import {EmailVerifyPage} from "./authentication/EmailVerifyPage";
import {ForgotPage} from "./authentication/ForgotPage";
import {ForgotSecondPage} from "./authentication/ForgotSecondPage";
import {Profile} from "./game/Profile";
import "./styles.css";
import {DeleteAccountPage} from "./basic/DeleteAccountPage";

type AppRouteObject = RouteObject & {children?: AppRouteObject[], handle?: {crumb: Crumb}};

const routes: AppRouteObject[] = [
    {
        path: "/",
        element: <WebpageOutlet />,
        errorElement: <NotFoundPage />,
        handle: {
            crumb: {path: "/", title: "Home"}
        },
        children: [{
            element: <LandingPage />,
            index: true
        }]
    },{
        path: "/app/delete",
        element: <WebpageOutlet />,
        errorElement: <NotFoundPage />,
        handle: {
            crumb: {path: "/", title: "Delete Account"}
        },
        children: [{
            element: <DeleteAccountPage />,
            index: true
        }]
    },{
        path: "/app/register",
        element: <WebpageOutlet />,
        // handle: {
        //     crumb: {path: "/app/register", title: "Create Account"}
        // },
        children: [{
            element: <RegisterPage />,
            index: true
        }]
    }, {
        path: "/app/login",
        element: <WebpageOutlet />,
        // handle: {
        //     crumb: {path: "/app/login", title: "Login"}
        // },
        children: [{
            element: <LoginPage />,
            index: true
        }]
    }, {
        path: "/app/email-verify",
        element: <WebpageOutlet />,
        // handle: {
        //     crumb: {path: "/app/login", title: "Login"}
        // },
        children: [{
            element: <EmailVerifyPage />,
            index: true
        }]
    }, {
        path: "/app/forgot",
        element: <WebpageOutlet />,
        // handle: {
        //     crumb: {path: "/app/login", title: "Login"}
        // },
        children: [{
            element: <ForgotPage />,
            index: true
        }]
    }, {
        path: "/app/forgot-code",
        element: <WebpageOutlet />,
        // handle: {
        //     crumb: {path: "/app/login", title: "Login"}
        // },
        children: [{
            element: <ForgotSecondPage />,
            index: true
        }]
    }, {
        path: "/app",
        element: <AppOutlet />,
        errorElement: <NotFoundPage />,
        handle: {
            crumb: {path: "/app", title: "Start"}
        },
        children: [
            {
                element: <Profile />,
                index: true
            }, /*{
                path: "/app/list/*",
                element: <PresentsPage />,
                handle: {
                    crumb: {path: "/app/friends", title: "Geschenke"}
                }
            }, {
                path: "/app/profile/*",
                element: <Profile />,
                handle: {
                    crumb: {path: "/app/profile", title: "Profil"}
                }
            }, {
                path: "/app/friends",
                element: <FriendLinkPage />,
                handle: {
                    crumb: {path: "/app/friend", title: "Freunde"}
                }
            }*/
        ]
    }
]
const appRouter = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<React.StrictMode><RouterProvider router={appRouter} /></React.StrictMode>);

reportWebVitals();
